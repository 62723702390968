html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
}

body {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
}

:root {
  --doc-height: 100vh;
  --doc-width: 100vw;
}

#root,
.App {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.with-bg-img {
  background-image: url(../../assets/images/bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: var(--doc-width);
  height: var(--doc-height);
}

.ant-modal {
  top: 12px;
  .ant-modal-content {
    padding: 22px 18px;
    border-radius: 20px;
    background: #fff;
    .ant-modal-body {
      margin-top: 30px;
    }
  }
}
